interface UserInput {
    input: HTMLElement,
    type: string
}

export default class UserContactDataStorageService {
    public fillInputsWithStoredValues(...inputs: UserInput[]) {
        inputs.forEach((item) => {
            if (item.input) {
                const input = item.input as HTMLInputElement;
                const value = localStorage.getItem(item.type);
                if (value) {
                    input.value = value;
                    input.dispatchEvent(new Event('keyup'));
                }
            }
        });
    }

    public storeUserInputValue(inputElement: HTMLElement, type: string) {
        if (inputElement) {
            this.storeUserValue(type, (inputElement as HTMLInputElement).value);
        }
    }

    public storeUserValue(type: string | UserContactDataType, value: string) {
        if (value) {
            localStorage.setItem(type.valueOf(), value);
        }
    }

    public getUserPhone(): string {
        return localStorage.getItem(UserContactDataType.PHONE) || "";
    }

    public getUserEmail(): string {
        return localStorage.getItem(UserContactDataType.EMAIL) || "";
    }

    public getUserName(): string {
        return localStorage.getItem(UserContactDataType.NAME) || "";
    }
}

export enum UserContactDataType {
    NAME = "name",
    EMAIL = "email",
    PHONE = "phone",
}
